import type { MaybeElement } from "@vueuse/core";

interface State {
  modal: MaybeElement;
}

export const useModalStore = defineStore("modal", {
  state: (): State => ({
    modal: null,
  }),
});
