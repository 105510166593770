<template>
  <div
    class="relative flex items-center justify-center overflow-hidden rounded-full"
    :class="{
      'bg-white ring-1 ring-neutral-500': !profileImage,
    }"
  >
    <img
      v-if="profileImage"
      :src="useCloudStorageUrl(`public/${profileImage}`)"
      v-bind="$attrs"
      alt="avatar"
      class="object-cover"
      loading="lazy"
    />
    <span v-else class="font-bold">
      {{ initials }}
    </span>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  profileImage?: string;
  username?: string;
}

const props = defineProps<Props>();

const initials = computed(() => props.username?.substring(0, 2).toUpperCase());
</script>
