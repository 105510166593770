<template>
  <LexmeaFAB
    class="bottom-12 right-16 lg:right-24"
    :class="{ 'right-5 lg:right-10': isFeedbackFABHidden }"
    :tooltipText="'Zum Seitenanfang'"
    :light="true"
    title="Go Up button"
    @click="scrollToTop()"
  >
    <i-heroicons-chevron-up class="h-5 w-5 text-white" />
  </LexmeaFAB>
</template>

<script lang="ts" setup>
const props = defineProps<{ container?: HTMLElement | null }>();

const feedbackDialogStore = useFeedbackDialogStore();
const { hide: isFeedbackFABHidden } = storeToRefs(feedbackDialogStore);

function scrollToTop() {
  const mainContainer = props.container
    ? props.container
    : document.querySelector("main");
  const { y } = useScroll(mainContainer, { behavior: "smooth" });
  y.value = 0;
}
</script>
