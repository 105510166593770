export const useFeedbackDialogStore = defineStore("feedbackDialog", () => {
  const open = ref<boolean>(false);
  const feedbackCloseMenuOpen = ref<boolean>(false);
  const hide: Ref<boolean> = useLocalStorage("hide-feedback-fab", false, {
    initOnMounted: true,
  });

  return {
    open,
    feedbackCloseMenuOpen,
    hide,
  };
});
