type State = {
  open: boolean;
  tab: "login" | "register";
};

export const useLoginModalStore = defineStore("loginModal", {
  state: (): State => ({
    open: false,
    tab: "login",
  }),
  actions: {
    openModal(tab: "login" | "register") {
      this.open = true;
      this.tab = tab;
    },
    closeModal() {
      this.open = false;
    },
  },
});
