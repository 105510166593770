import type { PageContext } from "vike/types";
import FloatingVue from "floating-vue";
import { createUnhead } from "./createUnhead";
import { createSSRApp } from "vue";
import Toast from "vue-toastification";
import { VueQueryPlugin, QueryClient, hydrate } from "@tanstack/vue-query";
import PageShell from "./PageShell.vue";
import { MotionPlugin } from "@vueuse/motion";

const buildQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 5 * 60 * 1000,
      },
    },
  });
};

export async function createApp(pageContext: PageContext) {
  const rootComponent = ref(markRaw(pageContext.config.Page));
  const PageWithWrapper = defineComponent({
    render() {
      return h(
        PageShell,
        {},
        {
          default: () => h(rootComponent.value),
        }
      );
    },
  });

  const app = createSSRApp(PageWithWrapper);
  const store = createPinia();

  const queryClient = buildQueryClient();
  const queryState = pageContext.data?.vueQueryState;
  if (queryState) {
    // hydrate initial state on server in case we fetched something in onBeforeRender
    hydrate(queryClient, queryState);
  }

  const head = createUnhead();

  app
    .use(head)
    .use(store)
    .use(VueQueryPlugin, { queryClient })
    .use(Toast, {
      containerClassName: "mt-12",
      toastClassName: "shadow-lg",
      hideProgressBar: true,
      transition: "Vue-Toastification__fade",
      transitionDuration: 150,
    })
    .use(MotionPlugin, {
      directives: {
        "lexmea-slide-top": {
          initial: {
            y: -100,
            opacity: 0,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 300,
              duration: 300,
            },
          },
        },
        "lexmea-slide-left": {
          initial: {
            x: -100,
            opacity: 0,
          },
          visibleOnce: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 500,
              duration: 300,
            },
          },
        },
        "lexmea-slide-right": {
          initial: {
            x: 100,
            opacity: 0,
          },
          visibleOnce: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 500,
              duration: 300,
            },
          },
        },
      },
    })
    .use(FloatingVue, { container: "#teleported" });

  // When doing Client Routing, we mutate pageContext (see usage of `app.changePage()` in `_default.page.client.js`).
  // We therefore use a reactive pageContext.
  const pageContextReactive = reactive(pageContext);

  // We use `app.changePage()` to do Client Routing, see `_default.page.client.js`
  objectAssign(app, {
    changePage: async (pageContext: PageContext) => {
      let returned = false;
      let err: unknown;
      app.config.errorHandler = (err_) => {
        if (returned) {
          console.error(err_);
        } else {
          err = err_;
        }
      };
      Object.assign(pageContextReactive, pageContext);
      rootComponent.value = markRaw(pageContext.config.Page);
      await nextTick();
      returned = true;
      if (err) throw err;
    },
  });

  // Make `pageContext` accessible from any Vue component
  setPageContext(app, pageContextReactive);

  return { app, store, head, queryClient };
}

// Same as `Object.assign()` but with type inference
function objectAssign<Obj extends object, ObjAddendum>(
  obj: Obj,
  objAddendum: ObjAddendum
): asserts obj is Obj & ObjAddendum {
  Object.assign(obj, objAddendum);
}
