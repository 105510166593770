import { TabsClient } from "~/api/tabs";
import type { DeskTab } from "../../models/ITab";

export const tabsMutations = () => {
  const queryClient = useQueryClient();
  const tabsClient = useApiClient(TabsClient);
  const { loggedIn } = storeToRefs(useUserStore());
  const { tabs: tabsQueries } = queries();

  const updateTabs = useDebounceFn(tabsClient.syncTabs, 5000, {
    rejectOnCancel: true,
  });

  const syncTabsMutation = useMutation({
    mutationFn: async (tabs: DeskTab[]) => {
      if (loggedIn.value) {
        return updateTabs(tabs);
      }
    },
    onMutate: async (/* newTabs */) => {
      await queryClient.cancelQueries({
        queryKey: tabsQueries.all.queryKey,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tabsQueries.all.queryKey });
    },
  });

  return {
    syncTabsMutation,
  };
};
