import type { IParagraphEntry } from "~/models/IUserEntry";

export function usePrefetchParagraph(
  legalTextSlug: MaybeRef<string | undefined>,
  slug: MaybeRef<string | undefined>,
  enabled: MaybeRef<boolean> = ref(true),
  initialTimeout = 0
) {
  if (import.meta.env.SSR) return;

  const client = useQueryClient();
  const { loggedIn } = storeToRefs(useUserStore());
  const { legalTexts, paragraphs, fieldOfLaws } = queries();

  const timeoutOver = useTimeout(initialTimeout);

  const prefetchEnabled = logicAnd(enabled, timeoutOver);

  const prefetch = async (legalText: string, s: string) => {
    const paragraph = await client.fetchQuery(
      legalTexts.bySlug(legalText)._ctx.paragraphBySlug(s)
    );

    const prefetchTypes: IParagraphEntry["type"][] = [
      "link_chain",
      "note",
      "schema",
      ...(loggedIn.value ? (["mark", "underline"] as const) : []),
    ];

    await Promise.all([
      ...prefetchTypes.map((type) =>
        client.prefetchQuery(
          paragraphs.byId(paragraph.id)._ctx.entriesByParagraph(type)
        )
      ),
      client.prefetchQuery(
        paragraphs.byId(paragraph.id)._ctx.incomingLinkChains
      ),
      client.prefetchQuery(fieldOfLaws.byId(paragraph.field_of_law_primary_id)),
      client.prefetchQuery(
        fieldOfLaws.byId(paragraph.field_of_law_primary_id)._ctx.schemas
      ),
      client.prefetchQuery(legalTexts.bySlug(legalText)),
      client.prefetchQuery(legalTexts.bySlug(legalText)._ctx.tableOfContents),
    ]);
  };

  watchEffect(async () => {
    const ltSlug = toValue(legalTextSlug);
    const pSlug = toValue(slug);
    if (prefetchEnabled.value && ltSlug && pSlug) {
      await prefetch(ltSlug, pSlug);
    }
  });
}
