import type { Input, KyInstance, Options } from "ky";

export type ApiClient = KyInstance;

export abstract class LexmeaApiClient {
  readonly #client: ApiClient;

  constructor(client: ApiClient) {
    if (!client) {
      const err = new Error("No client provided");
      console.error("No client provided: ", err.stack);
      throw err;
    }

    this.#client = client;
  }

  get = <T>(url: Input, options?: Options) => {
    return this.#client.get(url, options).json<T>();
  };

  post = <T>(
    url: Input,
    json?: Options["json"],
    options?: Omit<Options, "json">
  ) => {
    return this.#client.post(url, { json, ...options }).json<T>();
  };

  postBlob = (url: Input, json?: Options["json"], options?: Options) => {
    return this.#client.post(url, { json, ...options }).blob();
  };

  postForm = <T>(
    url: Input,
    data: Record<string, any>,
    putWorkaround: boolean = false,
    options?: Options
  ) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    if (putWorkaround) {
      formData.append("_method", "PUT");
    }
    return this.#client.post(url, { body: formData, ...options }).json<T>();
  };

  put = <T>(
    url: Input,
    json: Options["json"],
    options?: Omit<Options, "json">
  ) => {
    return this.#client.put(url, { json, ...options }).json<T>();
  };

  patch = <T>(
    url: Input,
    json: Options["json"],
    options?: Omit<Options, "json">
  ) => {
    return this.#client.patch(url, { json, ...options }).json<T>();
  };

  delete = (url: Input, options?: Options) => {
    return this.#client.delete(url, options);
  };
}
