import type { InjectionKey } from "vue";
import type { CanBeActive } from "~/models/IUserEntry";

const key: InjectionKey<Ref<CanBeActive | undefined>> = Symbol("activeEntry");

export function useActiveEntry() {
  const active = injectLocal(key, () => ref<CanBeActive>(), true);

  const provideActiveEntry = (entry: typeof active) => {
    provideLocal(key, entry);
  };

  return {
    active,
    provideActiveEntry,
  };
}
