<template>
  <footer
    class="flex w-full items-center justify-between bg-wild-sand px-2 text-4.5pt"
  >
    <span class="hidden md:inline-block"
      >© LexMea {{ new Date().getFullYear() }}</span
    >
    <div class="flex items-center space-x-4">
      <button
        v-show="onboardingTour.canShowTour.value"
        id="tour-btn"
        class="underline"
        @click="onboardingTour.startTour"
      >
        Tour
      </button>
      <button class="underline" @click="feedbackDialogStore.open = true">
        Feedback
      </button>
      <a class="underline" href="/agb">AGB</a>
      <a class="underline" href="/datenschutzhinweis">Datenschutz</a>
      <a class="underline" href="/impressum">Impressum</a>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const feedbackDialogStore = useFeedbackDialogStore();
const onboardingTour = useOnboardingTour();
</script>
