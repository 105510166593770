import type {
  IParagraphEntry,
  INewUserEntry,
  ILinkChain,
  INote,
  ISchema,
  IUserEntry,
  ISchemaWithText,
  IDetailUserEntry,
  IDownloadableSchema,
} from "~/models/IUserEntry";
import type { IPaginate } from "~/models/IPaginate";
import type { ChronikEntry } from "~/models/IBookshelf";
import { LexmeaApiClient } from "./apiClient";

export class EntriesClient extends LexmeaApiClient {
  fetchSchemaBySlug = (slug: string) => {
    return this.get<ISchemaWithText>(`api/schema/${slug}`);
  };

  fetchEntryById = <T extends IDetailUserEntry["type"]>(
    id: number,
    type: T
  ) => {
    return this.get<Extract<IDetailUserEntry, { type: T }>>(
      `api/${type}/${id}`
    );
  };

  fetchEntryByParagraph = <T extends IParagraphEntry["type"]>(
    paragraphId: number,
    type: T
  ) => {
    return this.get<Extract<IParagraphEntry, { type: T }>[]>(
      `api/legalTextParagraph/${paragraphId}/${type}`
    );
  };

  fetchIncomingLinkChains = (paragraphId: number) => {
    return this.get<ILinkChain[]>(
      `api/legalTextParagraph/${paragraphId}/links/incoming`
    );
  };

  fetchSchemasByFieldOfLaw = (fieldOfLawId: number) => {
    return this.get<ISchema[]>(`api/fieldOfLaw/${fieldOfLawId}/schema`);
  };

  saveEntry = <T extends INewUserEntry>({
    paragraphId,
    entry,
  }: {
    paragraphId: number;
    entry: T;
  }) => {
    return this.post<Extract<IDetailUserEntry, { type: T["type"] }>>(
      `api/legalTextParagraph/${paragraphId}/${entry.type}`,
      entry
    );
  };

  getEntryTitle = ({
    nodeStartId,
    wordStartOffset,
  }: {
    nodeStartId: number;
    wordStartOffset: number;
  }) => {
    return this.get<{ title: string }>("api/entry/title", {
      searchParams: {
        node_start_id: nodeStartId,
        word_start_offset: wordStartOffset,
      },
    });
  };

  updateEntry = <T extends IParagraphEntry>(entry: T) => {
    return this.put<Extract<IDetailUserEntry, { type: T["type"] }>>(
      `api/${entry.type}/${entry.id}`,
      entry
    );
  };

  deleteEntry = async <T extends IUserEntry>(entry: T) => {
    await this.delete(`api/${entry.type}/${entry.id}`);
  };

  restoreEntry = <T extends ChronikEntry>(entry: T) => {
    return this.post<Extract<IDetailUserEntry, { type: T["type"] }>>(
      `api/${entry.type}/${entry.id}/restore`
    );
  };

  searchSchemas = (query: string) => {
    return this.get<IPaginate<ISchemaWithText>>("api/schema/search", {
      searchParams: { query },
    });
  };

  searchNotes = (query: string) => {
    return this.get<IPaginate<INote>>("api/note/search", {
      searchParams: { query },
    });
  };

  publishSchema = (schemaId: number): Promise<ISchemaWithText> => {
    return this.post<ISchemaWithText>(`api/schema/${schemaId}/publish`);
  };

  unpublishSchema = (schemaId: number): Promise<ISchemaWithText> => {
    return this.post<ISchemaWithText>(`api/schema/${schemaId}/unpublish`);
  };

  schemasForDownload = (
    schemaIds: number[]
  ): Promise<IDownloadableSchema[]> => {
    const ids = schemaIds.join("&ids[]=");
    return this.get<IDownloadableSchema[]>("api/schema/download", {
      searchParams: { ids },
    });
  };
}
